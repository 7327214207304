<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_estate") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateEstate"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.getters["estate/getEstate"]);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
    });

    const formElements = ref();

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: estate.value.name,
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "address",
          label: "address",
          rules: ["isRequired"],
          type: "text",
          value: estate.value.address,
          variable: "address",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "postal-code",
          label: "postal_code",
          rules: ["isRequired", "isPostalCode"],
          type: "text",
          value: estate.value.postalCode,
          variable: "postalCode",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "city",
          label: "city",
          rules: ["isRequired"],
          type: "text",
          value: estate.value.city,
          variable: "city",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "businessId",
          label: "business_id",
          rules: ["isRequired", "isBusinessId"],
          type: "text",
          value: estate.value.businessId,
          variable: "businessId",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "identifier",
          label: "identifier",
          rules: [],
          type: "text",
          value: estate.value.identifier,
          variable: "identifier",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "datepicker",
          id: "energy-certificate-date",
          label: "energy_certificate",
          rules: [],
          value: estate.value.energyCertificate
            ? getISODate(new Date(estate.value.energyCertificate))
            : null,
          variable: "energyCertificate",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    onMounted(() => {
      store.dispatch("estate/getEstate", route.params.estateId);
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      t,
      updateEstate: (data) =>
        store.dispatch("estate/updateEstate", { id: estate.value.id, ...data }),
      waiting,
    };
  },
};
</script>
